import React, { Component } from "react";
import {
    Carousel,
    Image,
} from "react-bootstrap";

class SlideAbout extends Component {
  render() {
    return (
      <div>
        <Carousel className="slide-about">
          <Carousel.Item>
            <Image
              alt="900x500"
              className="lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
              data-src={require("../../../../images/jpg/slide-3.jpg")}
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              alt="900x500"
              className="lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
              data-src={require("../../../../images/jpg/slide-2.jpg")}
            />
          </Carousel.Item>
        </Carousel>
        <div className="slide-about-desktop">
          <Image
            alt="900x500"
            className="lazyload"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
            data-src={require("../../../../images/jpg/slide-3.jpg")}
          />
          <Image
            alt="900x500"
            className="lazyload"
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" 
            data-src={require("../../../../images/jpg/slide-2.jpg")}
          />
        </div>
      </div>
    );
  }
}

export default SlideAbout;
