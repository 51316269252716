import React, { Component } from "react"
import SlideAbout from "./SlideAbout"
import { Image, Button } from "react-bootstrap"
import { bgAboutUs } from '../../../images'
import { BackgroundImages } from "../../../shared"

class AboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
    };
    this.toggleBox = this.toggleBox.bind(this);
    this.renderOurTeam = this.renderOurTeam.bind(this);
    this.renderContent = this.renderContent.bind(this);
  }

	toggleBox() {
		const { opened } = this.state;
    this.setState({
  		opened: !opened,
  	});
	}

  renderOurTeam () {
    return (
      <div className="wrapper-team">
        <h2 className="title-mid">Our Team</h2>
        <div className="left">
          <div className="box-member">
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
          </div>
          <div className="box-member left">
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
          </div>
        </div>
        <div className="right">
          <div className="box-member">
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
          </div>
          <div className="box-member left">
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
            <div className="member">
              <Image data-src={require("../../../images/member/member-1.png")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-member lazyload" />
              <div className="description">
                <p className="name-member">Nguyen Van A</p>
                <p className="job-member">Manager</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderContent () {
    var { title } = this.props;
		const { opened } = this.state;

  	if (opened){
  		title ='Show More';
  	}else{
  		title ='Hide';
  	}

    return (
      <BackgroundImages style={{
        backgroundImage: `url(${bgAboutUs})`,
        zIndex: -1
      }}>
      <div className="middle-about-us">
          <h2 className="title-mid">About Us</h2>
          <div className="box-middle-about">
            <p className="content-mid right">We Opentechiz's love + 1.000 Projects<br/><br/> More than 1,000 projects with clients worldwide rely Open Techiz to grow and development
              their e-commerce presence, Mobile/ Web Applications. 200+ clients
            </p>
            <Image data-src={require("../../../images/jpg/about-1.jpg")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-about lazyload" />
          </div>
          <div className={this.state.opened?'hidden' : ''}>
            <div className="box-middle-about">
              <p className="content-mid">
                <span className="title-content-mid">
                  "One Stop Shop" for your online success
                </span>
                Open Techiz growing with your successful business by building around the design, development, intergration and support of Magento and e-Commerce website
                <br/><br/>
                We aim to deliver timely, on budget and high quality works that ensure clients satisfaction with a wide rages of services, from small tasks (bug fix, content update) to the whole solution. Our goals are looking for the long-term relationship with all clients and become success together.
                <br/><br/>
                Our excellent quality with 5 stars rating on outsourcing marketcomes which is guaranteed with 99% satisfaction of our customers to help them achieve business success.
              </p>
              <Image data-src={require("../../../images/jpg/about-2.jpg")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-about lazyload" />
            </div>
            <div className="box-middle-about">
              <p className="content-mid right">
                <br/><br/>
                Located at Hanoi, Viet Nam and Established since 2007, 8 years of experiences: 200+ clients, 1000+ projects, Open Techiz have grown to be a premium full service e-commerce agency to provide services for leading brands and e-retailers.
              </p>
              <Image data-src={require("../../../images/jpg/about-3.jpg")} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" alt="900x500" className="image-about lazyload" />
            </div>
          </div>
          <div className="btn-middle">
            <Button className="btn-show-hide" onClick={this.toggleBox}>
              {title}
            </Button>
          </div>
      </div>
    </BackgroundImages>
    );
  }

  render() {
    return (
      <div className="wrapper-about-us">
        <SlideAbout />
        <div className="top-about-us">
          <h1 className="title">
            A smart e-commerce develop agency
          </h1>
          <h2 className="text">
            We use the best technologies for the development of web apps & mobile apps
          </h2>
        </div>
        {this.renderContent()}
        {this.renderOurTeam()}
      </div>
    )
  }
}

export default AboutUs;
